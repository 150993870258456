import React from "react"
import Seo from "./Seo"

const Layout = ({ children }) => (
  <>
    <Seo />
    <div className="flex flex-col min-h-screen bg-gray-200">
      <header className="p-2 md:p-6 bg-blue-500">
        <h1 className="mx-auto text-2xl text-gray-200 font-bold">
          Window Grille Clips.com
        </h1>
      </header>
      {children}
      <footer className="py-2 text-center text-gray-600 text-xs">
        &copy; <a href="http://madeparts.com">MadeParts.com</a>
      </footer>
    </div>
  </>
)

export default Layout
